import React from 'react';
import './Membership.css';

const Membership = () => {
  return (
    <div className="membership-container">
      <h1>Monthly Membership</h1>
      <p>Enjoy unlimited access to our pinball machines for only $80/month!</p>
      <a 
        href="https://buy.stripe.com/eVabKu2Vyd9e62Y5kk" 
        target="_blank" 
        rel="noopener noreferrer"
        className="stripe-button"
      >
        Sign Up for Monthly Membership
      </a>
    </div>
  );
};

export default Membership;